import React, { lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloProvider,
} from "@apollo/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { setContext } from "@apollo/client/link/context";
import { getLocalStorageItem } from "./config/AuthSetting";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";

const Layout = lazy(() => import("./containers/Layout"));
const SuperAdminLayout = lazy(() => import("./containers/SuperAdminLayout"));
const AuthLayout = lazy(() => import("./containers/AuthLayout"));
const Confirmation = lazy(() => import("./pages/auth/Confirmation"));
const OutlookComp = lazy(() => import("./pages/outlook"));
// get the authentication token from local storage if it exists
const link = new createHttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_SERVER_URL}/graphql`,
  // credentials: 'same-origin',
});
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getLocalStorageItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});
const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache(),
});

const queryClient = new QueryClient();

const App = () => {
  const user = getLocalStorageItem("user");
  const superAdmin = user?.role === "super";
  const token = getLocalStorageItem("token");

  return (
    <QueryClientProvider client={queryClient}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <ApolloProvider client={client}>
          <Router>
            <AccessibleNavigationAnnouncer />
            <Switch>
              <Route path="/confirmation" component={Confirmation} />
              <Route path="/outlook/callback" component={OutlookComp} />
              <Route
                path="/"
                component={
                  token ? (superAdmin ? SuperAdminLayout : Layout) : AuthLayout
                }
              />
            </Switch>
          </Router>
        </ApolloProvider>
      </GoogleOAuthProvider>
    </QueryClientProvider>
  );
};

export default App;
