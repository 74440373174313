import { FetchDynamicField } from "../../services/workspace/taskManager";
import {
  setAllDynamicFields,
  setSelectGroup,
  setFormFields,
  setAddFormType,
  setAddGroup,
} from "./action";
import dynamicFieldReducer from "./reducer";

const getAllDynamicFields = () => {
  return async (dispatch) => {
    try {
      const res = await FetchDynamicField();
      if (res?.status === 200) {
        dispatch(setAllDynamicFields(res?.data || []));
      }
    } catch (error) {
      console.log("Error fetching dynamic fields:", error);
    }
  };
};

export {
  dynamicFieldReducer,
  getAllDynamicFields,
  setAllDynamicFields,
  setSelectGroup,
  setFormFields,
  setAddFormType,
  setAddGroup,
};
