import * as Type from "./types";
const ConversationFilterReducer = (
  state = {
    labelOpen: false,
    agentOpen: false,
    channelOpen: false,
    dateOpen: false,
    value: {},
    input: "",
    startDate: null,
    endDate: null,
    labelIds: [],
    agentIds: [],
    selectedReasons: [],
    channelIds: [],
    isSearchOpen: false,
    keyword: "",
  },
  action
) => {
  switch (action.type) {
    case Type.SET_CONVERSATION_FILTER:
      state = {
        ...state,
        filterValues: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
};

export default ConversationFilterReducer;
